.Host {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Host footer {
  padding: 10px;
  background-color: #fff;
  font-size: 30px;
  text-align: center;
}

.RoomCode {
  font-weight: 500;
}

.Host main {
  flex: 1;
}

.PlayersJoining {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.PlayersJoined {
  width: fit-content;
  text-align: left;
  margin: 0 auto;
}

.GhostPlayer {
  margin-left: 0.5em;
}

.Players {
  margin: 10px 20px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
  min-width: 50vw;
}

.JoinedPlayer {
  margin: 10px 0;
}

.Selecting {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Voting {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.VoteNow {
  flex: 0;
  text-align: center;
  padding: 1em;
  font-size: 60px;
}

.Status {
  text-align: center;
  font-size: 20px;
  padding: 1em;
}

.Reveal {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  justify-content: space-around;
}

.Cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-around;
  align-items: flex-end;
}

.WinningCards {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.WinningCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.PlayedByText {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
  width: var(--small-card-width);
}

.AllScores {
  padding: 1em 2em;
  width: fit-content;
  background-color: #fff;
  box-shadow: 2px 2px 10px -2px #000000;
}

.AllScoresTitle {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 1em;
}

@media (max-height: 620px) {
  .AllScores {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .AllScoresTitle {
    font-size: 25px;
    margin-bottom: 2px;
  }
}


.PlayerScores {
  display: grid;
  grid-auto-columns: 1fr;
  gap: 0.5em;
}

.PlayerScore {
  grid-row: 1;
}

.StartNewRoundButton,.ReadyToStartButton,.AddGhostButton {
  font-size: 30px;
}

.PlayerScoreName {
  font-weight: bold;
}