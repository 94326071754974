.PickCards,.Vote {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PickCards h1,.Vote h1 {
  text-align: center;
  margin-block: 10px;
}

.Waiting {
  background-color: #fff;
  margin: 10px 20px;
  padding: 20px;
  text-align: center;
  box-shadow: 2px 2px 10px -2px #000000;
}

