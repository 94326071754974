.TextInput {
  --border-bottom-width: 3px;
  background-color: #f3f3f3;
  border: none;
  border-bottom: var(--border-bottom-width) solid transparent;
  padding: 0.2em;
}

.TextInput:focus-visible {
  outline: none;
}

.TextInput:focus {
  box-sizing: border-box;
  border: none;
  border-bottom: var(--border-bottom-width) solid var(--purply);
}
