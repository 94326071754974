.WhiteCard {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  background-color: #fff;
  color: #000;
  box-shadow: 2px 2px 10px -2px #000000;
}

.WhiteCard > .Annotation {
  text-align: center;
  font-size: 75%;
  color: var(--iris);
  border: thin solid var(--iris);
  margin-bottom: 5px;
}

.WhiteCard.Clickable {
  cursor: pointer;
}

.WhiteCard.Selected {
  background-color: var(--vista-blue);
}
