
.ShowAndPick {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PlayerSubmitButton {
    margin: 10px;
    font-size: 30px;
}

.ShowAndPick h1 {
    text-align: center;
    margin-block: 10px;
}

.PickWhiteCards {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;
    justify-content: center;

    overflow-y: auto;
}

.PickButtonArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
}