.Lobby {
  height: 100%;
  display: flex;
  justify-content: center;
}

.Choosing {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Title {
  text-align: center;
  margin-top: 1em;
  font-size: 2.2em;
  --border: 1px solid #000000;
  border-top: var(--border);
  border-bottom: var(--border);
}

.NamesAgainst {
  font-style: italic;
  /*color: #2a2a2a;*/
  font-size: 0.8em;
}

.BrowseButton {
  margin: 10px 0;
}

.CrossFade {
  transition: opacity 3s ease-in;
}
.CrossFadeHide {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.CrossFadeShow {
  opacity: 100;
  transition: opacity 500ms ease-out;
}

.Choices {
  flex: 1;
  margin: auto 0;

  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
  justify-content: center;
}

@media (min-aspect-ratio: 8/5) {
  .Choices {
    flex-direction: row;
    align-items: center;
  }
}

.ChoiceDivider {
  text-align: center;
  font-size: 60px;
}

.ChoiceButton {
  display: block;
  font-size: 80px;
}

.Details {
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.JoinField {
  margin: 0.5em 0;
}

.JoinField label {
  font-size: 30px;
  display: block;
}

.JoinInput {
  box-sizing: border-box;
  width: 100%;
  font-size: 40px;
  display: block;
}

.JoinButton {
  display: block;
  font-size: 30px;
  width: 100%;
}
