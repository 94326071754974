

.Card.small {
  --card-dynamic-height: 17vh;
  --card-dynamic-width: 13vh;
  --max-card-height: 160px;
  --max-card-width: 120px;
  --min-card-font-size: 12px;
  --card-dynamic-font-size: 1.7vh;
  --max-card-font-size: 18px;
}

.Card.medium {
  --card-dynamic-height: 32vh;
  --max-card-height: 300px;
  --card-dynamic-width: 24vh;
  --max-card-width: 225px;
  --min-card-font-size: 16px;
  --card-dynamic-font-size: 3vh;
  --max-card-font-size: 30px;
}

.Card {
  height: clamp(120px, var(--card-dynamic-height), var(--max-card-height));
  width: clamp(90px, var(--card-dynamic-width), var(--max-card-width));
  border-radius: 2vh;
  padding: 2vh;
  font-size: clamp(var(--min-card-font-size), var(--card-dynamic-font-size), var(--max-card-font-size));
}

.Card.large {
  /* About 10:6.2:1 ratio */
  height: 70vh;
  width: 44vh;
  border-radius: 5vh;
  padding: 5vh;
  font-size: 7vh;
}