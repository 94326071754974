.Browse {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BrowseCardsArea {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.BrowseControls {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

.BlackCardArea {
    display: flex;
    align-items: center;
    justify-content: right;
}

.WhiteCardsArea {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
}