button.Button {
  color: #000;
  background-color: #f3f3f3;
  border: thin solid black;
  padding: 15px;
  border-radius: 5px
}

@media (max-height: 620px) {
  button.Button.ShrinkVertical {
    padding: 2px 15px;
  }
}

button.Button:hover {
  cursor: pointer;
  background-color: #ccc;
}

button.Button:active {
  color: #fff;
  background-color: #111;
}

button.Button:focus-visible {
  outline: solid 2px var(--purply);
}

button.Button:disabled {
  background-color: #e7e7e7;
  color: #a2a2a2;
  cursor: inherit;
}