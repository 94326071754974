@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&display=swap');

:root {
  --small-card-width: max(15vh,15vw);
  /* https://coolors.co/4545c4-91c4f2-8ca0d7-151515-a63d40 */
  --purply: #4545c4ff;
  --iris: #4545c4ff;
  --jordy-blue: #91c4f2ff;
  --vista-blue: #8ca0d7ff;
  --night: #151515ff;
  --redwood: #a63d40ff;
  --background-color: #aaa;

  --height-breakpoint: 620px;
}

html {
  font-family: 'Inter', sans-serif;
  background-color: var(--background-color);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html, body, .root {
  height: 100%;
}

#root {
  height: 100%;
}

.block {
  display: block;
}

.TextCenter {
  text-align: center;
}

.FlexGrowOnly {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}